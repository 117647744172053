<template>
  <div class="merchant-add">
    <!-- 头部 -->
    <!-- <GlobalInfoBar
      title="回收商到货管理"
      content="通过分转授权或外部渠道选择收货方为平台的订单，统计订单发货情况、旧机入库到繁星中转仓的情况"
    /> -->
    <GlobalChunk icon="search" title="筛选查询">
      <!--  头部 -->
      <template v-slot:filter>
        <!-- 筛选参数 -->
        <GlobalForm
          round
          :init-data="initData"
          :form-item-list="formItemList"
          confirm-btn-name="查询"
          size="mini"
          :needBtnLoading="true"
          @handleChange="handleChange"
          :inline="true"
          @handleConfirm="handleConfirm"
        >
          <el-form-item
            slot="stateList"
            label="订单状态"
            style="margin: 0"
            label-width="93px"
          >
            <el-select
              v-model="stateList"
              placeholder="请选择订单状态"
              clearable
              multiple
              filterable
            >
              <el-option
                v-for="item in stateArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="矩阵订单状态"
            slot="matrixStateList"
            style="margin: 0"
            label-width="100px"
          >
            <el-select
              v-model="matrixStateList"
              placeholder="请选择矩阵订单状态"
              clearable
              multiple
              filterable
            >
              <el-option
                v-for="item in smattateArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="旧机价值"
            slot="operateValue"
            style="margin: 0"
            label-width="93px"
          >
            <el-input
              v-model.number="priceLow"
              placeholder="输入起始值"
              @clear="clearPrice('low')"
              @change="(val) => priceChang(val, 'low')"
              style="width: 130px"
              clearable
            />
            ～
            <el-input
              v-model.number="priceUp"
              placeholder="输入结束值"
              @clear="clearPrice('up')"
              @change="(val) => priceChang(val, 'up')"
              style="width: 130px; margin-right: 20px"
              clearable
            />
          </el-form-item>
          <el-form-item
            v-if="
              param.expressState == '03' ||
              param.expressState == '04' ||
              param.expressState == '05' ||
              param.expressState == '06'
            "
            label="收件时间"
            slot="entryTime"
            style="margin: 0"
            label-width="75px"
          >
            <el-date-picker
              v-model="entryTime"
              type="daterange"
              start-placeholder="开始日期"
              range-separator="~"
              end-placeholder="结束日期"
              clearable
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
          <template v-slot:default>
            <el-button
              icon="el-icon-download"
              size="small"
              type="success"
              style="margin-right: 10px"
              :loading="excelLoading"
              @click="exportExcel"
              >{{ excelLoading ? "正在导出" : "导出Excel" }}
            </el-button>
            <!-- <el-button
              icon="el-icon-download"
              size="small"
              type="warning"
              v-loading="allExcelLoading"
              @click="exportExcel('all')"
              >{{ excelLoading ? "正在导出" : "导出全部数据" }}
            </el-button> -->
          </template>
        </GlobalForm>
      </template>
      <!-- table -->
      <template v-slot:default>
        <div class="tab_inner">
          <div class="SendTo">
            <div
              v-for="item in sendItems"
              :key="item.id"
              :class="sendTabChoose == item.id ? 'sendActive' : ''"
              class="sendItem"
              @click="acitonsendTabChoose(item.id)"
            >
              {{ item.text }}
              <!-- {{
                item.sendPlatform !== undefined
                  ? `${item.text}(${
                      item.sendPlatform > 99999 ? "99999+" : item.sendPlatform
                    }台)`
                  : item
              }} -->
            </div>
          </div>

          <!-- 状态筛选 -->
          <TabStatusSelect
            :activeIndex="tabIndex"
            :tabData="tabData"
            unitName="台"
            @tabAction="tabAction"
          />

          <div
            style="width: 100%; display: flex; justify-content: space-between"
          >
            <div>
              <el-checkbox
                style="margin: 10px 0 0 10px"
                v-model="timeoutType"
                >{{ screenContent[tabIndex] }}</el-checkbox
              >
              <el-checkbox style="margin-left: 20px" v-model="filterPrice"
                >筛选200元以上订单</el-checkbox
              >
              <el-checkbox v-model="Abnormalentry"
                >筛选入库异常订单</el-checkbox
              >
            </div>
            <div>
              <el-button
                type="primary"
                size="mini"
                plain
                @click="timeRankChange"
              >
                <template v-if="param.ackTimeRankType == '00'">
                  优先查看最早成交
                  <i class="el-icon-bottom el-icon--right"></i>
                </template>
                <template v-else>
                  优先查看最近成交
                  <i class="el-icon-top el-icon--right"></i>
                </template>
              </el-button>
              <el-button
                type="warning"
                size="mini"
                plain
                @click="handleConfigColumns"
                >配置列数据</el-button
              >
            </div>
          </div>
        </div>
        <!-- table列表 -->
        <el-table
          border
          size="small"
          :data="tableData"
          v-loading="loading"
          v-if="refresh"
          :header-cell-style="{ background: '#E4ECFD', padding: 0 }"
        >
          <el-table-column
            v-for="item in tableColumns"
            :key="item.prop"
            :prop="item.prop"
            align="center"
            :label="item.label"
            :width="item.width"
            :fixed="item.prop === 'action' ? 'right' : null"
          >
            <template v-slot="{ row }">
              <!-- 操作 -->
              <div
                v-if="item.prop === 'action'"
                style="display: flex; justify-content: center; padding: 0 10px"
              >
                <el-button
                  v-for="item in tabData[tabIndex].actionInner"
                  :key="item"
                  :type="buttonTypeMap[item]"
                  round
                  @click="
                    actionClick(
                      item === '修改快递单号'
                        ? row.expressNo
                          ? '修改快递单号'
                          : '添加快递单号'
                        : item === '添加备注'
                        ? row.expressDptmNote === '--'
                          ? '添加备注'
                          : '修改备注'
                        : item,
                      row
                    )
                  "
                  size="mini"
                >
                  {{
                    item === "修改快递单号"
                      ? row.expressNo
                        ? "修改快递单号"
                        : "添加快递单号"
                      : item === "添加备注"
                      ? row.expressDptmNote === "--"
                        ? "添加备注"
                        : "修改备注"
                      : item
                  }}
                </el-button>
                <el-button
                  v-if="
                    ['01', '02', '03'].includes(tabData[tabIndex].paramResult)
                  "
                  type="danger"
                  round
                  size="mini"
                  @click="$refs['cancelDeliveryDia'].open(row)"
                  >取消发货</el-button
                >
                <el-button
                v-if="
                    ['06'].includes(tabData[tabIndex].paramResult)&&row.outType=='haihuishou'&&row.takeState=='01'
                  "
                 type="danger" 
                 plain
                 style="border-radius: 20px;"
                  size="mini"
                  @click="actionClick('暂停收货详情', row)"
                  >暂停收货详情</el-button
                >
                <el-button
                v-if="
                    ['06'].includes(tabData[tabIndex].paramResult)&&row.outType=='haihuishou'&&row.takeState=='00'
                  "
                 type="danger" 
                 round
                  size="mini"
                  @click="actionClick('暂停收货', row)"
                  >暂停收货</el-button
                >
                <el-button
                  v-if="
                    ['06'].includes(tabData[tabIndex].paramResult)&&row.outType!=='haihuishou'
                  "
                 type="danger" 
                 round
                  size="mini"
                  @click="actionClick('取消线下发货', row)"
                  >取消线下发货</el-button
                >
              </div>
              <!-- 订单号 -->
              <div v-else-if="item.prop === 'orderNo'">
                <a
                  v-if="row[item.prop] !== '--'"
                  :href="`/RecallOrder/Details?type=edit&id=${row[item.prop]}`"
                  target="_blank"
                  style="text-decoration: underline; color: #0981ff"
                >
                  {{ row[item.prop] }}
                </a>
                <span v-else>--</span>
              </div>
              <!-- 成交店员-->
              <div v-else-if="item.prop === 'ackStaffName'">
                <span>
                  {{ row[item.prop] }} -- {{ row.ackStaffMobile }}
                  <br v-if="row['ackStaffName'] !== '--'" />
                  <span
                    v-if="row['ackStaffName'] !== '--'"
                    style="
                      padding: 3px 10px;
                      border-radius: 4px;
                      background: #80c269;
                      color: #fff;
                    "
                    >{{ row["ackStaffIsMajor"] }}</span
                  >
                </span>
              </div>
              <!-- 用户成交价 -->
              <div v-else-if="item.prop === 'finalPrice'">
                <span style="color: red" v-if="row[item.prop] !== '--'">
                  {{ row[item.prop] }}</span
                >
                <span v-else>--</span>
              </div>
              <!-- 入库异常标记 -->
              <div v-else-if="item.prop === 'normalEntry'">
                <p v-if="row.receiveTime != '--'">
                  <span
                    style="color: red"
                    v-if="row.dayReceiveUnInto.isException"
                  >
                    超{{ row.dayReceiveUnInto.dayNum }}天未入库</span
                  >
                  <span style="color: rgb(9, 129, 255)" v-else>正常</span>
                </p>
                <p v-else>--</p>
              </div>
              <!-- 旧机价值 -->
              <div v-else-if="item.prop === 'manualQuotePrice'">
                <span style="color: red" v-if="row[item.prop] !== '--'">
                  {{ row[item.prop] }}</span
                >
                <span v-else>--</span>
              </div>
              <!-- 入库单据 -->
              <div v-else-if="item.prop === 'intoNo'">
                <div
                  v-if="row[item.prop] !== '--'"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <span>{{ row[item.prop] }}</span>
                  <span
                    style="
                      color: #0981ff;
                      border: 1px solid #0981ff;
                      border-radius: 4px;
                    "
                  >
                    {{ row.intoWarehouseId == 1 ? "成都仓" : "深圳仓" }}</span
                  >
                </div>
                <span v-else>--</span>
              </div>
              <!-- 出库单据 -->
              <div v-else-if="item.prop === 'outNo'">
                <div
                  v-if="row[item.prop] !== '--'"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <span>{{ row[item.prop] }}</span>
                  <span
                    style="
                      color: #0981ff;
                      border: 1px solid #0981ff;
                      border-radius: 4px;
                    "
                  >
                    {{ row.intoWarehouseId == 1 ? "成都仓" : "深圳仓" }}</span
                  >
                </div>
                <span v-else>--</span>
              </div>
              <!-- 背贴码 -->
              <div
                v-else-if="item.prop == 'barCode' || item.prop == 'orderUnique'"
              >
                <div v-if="row[item.prop] != '--'" class="column">
                  <span> {{ row.barCode }}</span>
                  <span>繁星码</span>
                </div>
                <div v-else class="column">
                  <span> {{ row.orderUnique }}</span>
                  <span v-if="row.orderUnique != '--'">唯一码</span>
                </div>
              </div>
              <!-- 矩阵/外部订单号 -->
              <div v-else-if="item.prop === 'matrixOrderNo'">
                <span v-if="row.matrixOrderNo != '--'">{{
                  row.matrixOrderNo
                }}</span>
                <span v-else-if="row.orderNoOneline != '--'">{{
                  row.orderNoOneline
                }}</span>
                <span v-else>--</span>
              </div>
              <!-- 矩阵订单状态 -->
              <div v-else-if="item.prop === 'matrixState'">
                <p v-if="row.matrixOrderNo != '--'">
                  <span v-if="row.matrixState == '00'">待确认</span>
                  <span v-else-if="row.matrixState == '01'">已确认</span>
                  <span v-else-if="row.matrixState == '02'">待支付</span>
                  <span v-else-if="row.matrixState == '03'">已绑码</span>
                  <span v-else-if="row.matrixState == '04'">已收货</span>
                  <span v-else-if="row.matrixState == '05'">降价收货</span>
                  <span v-else-if="row.matrixState == '10'">已取消</span>
                  <span v-else-if="row.matrixState == '20'">已作废</span>
                  <span v-else-if="row.matrixState == '30'">已退回</span>
                </p>
                <span v-else>--</span>
              </div>
              <!-- 发货异常标记 -->
              <div
                v-else-if="
                  item.prop === 'dayUnSendGood' ||
                  item.prop === 'dayUnSign' ||
                  item.prop === 'dayUnGet' ||
                  item.prop === 'dayOut' ||
                  item.prop === 'dayInto' ||
                  item.prop === 'dayOffUnTake' ||
                  item.prop === 'dayUnInto' ||
                  item.prop === 'dayUnTake'
                "
              >
                <span
                  v-if="
                    item.prop === 'dayUnSendGood' &&
                    row[item.prop].isException === true
                  "
                  style="color: red"
                >
                  超{{ row[item.prop].dayNum }}天未发货
                </span>
                <span
                  v-if="
                    item.prop === 'dayUnSign' &&
                    row[item.prop].isException === true
                  "
                  style="color: red"
                >
                  超{{ row[item.prop].dayNum }}天未签收
                </span>
                <span
                  v-if="
                    item.prop === 'dayUnGet' &&
                    row[item.prop].isException === true
                  "
                  style="color: red"
                >
                  超{{ row[item.prop].dayNum }}天未取件
                </span>
                <span
                  v-if="
                    item.prop === 'dayOut' &&
                    row[item.prop].isException === true
                  "
                  style="color: red"
                >
                  已出库超{{ row[item.prop].dayNum }}天
                </span>
                <span
                  v-if="
                    item.prop === 'dayInto' &&
                    row[item.prop].isException === true
                  "
                  style="color: red"
                >
                  已入库超{{ row[item.prop].dayNum }}天
                </span>
                <span
                  v-if="
                    item.prop === 'dayOffUnTake' &&
                    row[item.prop].isException === true
                  "
                  style="color: red"
                >
                  已发货{{ row[item.prop].dayNum }}天
                </span>
                <span
                  v-if="
                    item.prop === 'dayUnInto' &&
                    row[item.prop].isException === true
                  "
                  style="color: red"
                >
                  超{{ row[item.prop].dayNum }}天未入库
                </span>
                <span
                  v-if="
                    item.prop === 'dayUnTake' &&
                    row[item.prop].isException === true
                  "
                  style="color: red"
                >
                  超{{ row[item.prop].dayNum }}天未收货
                </span>
                <span
                  v-else-if="row[item.prop].isException === false"
                  style="color: #0981fe"
                  >正常</span
                >
              </div>
              <!-- 是否上报异常机-->
              <div v-else-if="item.prop === 'isReportedException'">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div>
                    <span v-if="row[item.prop] == true">是</span>
                    <span v-else>否</span>
                  </div>
                  <div>
                    <a
                      v-if="row[item.prop] == true"
                      :href="`/transferManagement/abnormalReminder?order=${row.orderNo}`"
                      target="_blank"
                      style="text-decoration: underline; color: #0981ff"
                      >查看催货记录</a
                    >
                    <!-- <span v-else style="text-decoration:underline;color:red;cursor:pointer;" @click="actionClick('上报异常催货',row)" >上报异常</span> -->
                    <span
                      v-else
                      :style="{
                        color:
                          row.channelId != '--' || row.channelId != null
                            ? 'red'
                            : '#7b7b7b',
                      }"
                      style="text-decoration: underline; cursor: pointer"
                      @click="actionClick('上报异常催货', row)"
                      >上报异常</span
                    >
                  </div>
                </div>
              </div>
              <!-- 是否线上支付-->
              <div v-else-if="item.prop === 'isEnablePay'">
                <span v-show="row[item.prop] == true">是</span>
                <span v-show="row[item.prop] == false">否</span>
              </div>
              <!-- 催货跟进记录 -->
              <div v-else-if="item.prop === 'errorTrackFollowCount'">
                <!-- <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;">
                  <div>
                    <span style="color:#0981ff;cursor:pointer;text-decoration:underline;" @click="noteFn(row)">{{
                      row.noteNum }}</span>
                  </div>
                  <span style="color:red;text-decoration:underline;cursor:pointer;color: #0981ff;"
                    @click="actionClick('添加人工跟进', row)">添加人工跟进</span>
                </div> -->
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center
                  "
                >
                  <div>
                    <span
                      v-if="row.errorTrackFollowCount != '--'"
                      @click="$refs['followRecordDia'].open(row.orderNo)"
                      style="
                        cursor: pointer;
                        color: red;
                        text-decoration: underline;
                      "
                      >{{ row.errorTrackFollowCount }}</span
                    >
                    <span v-else style="color: #7f7f7f">暂无跟进</span>
                  </div>
                  <span
                    style="
                      color: red;
                      text-decoration: underline;
                      cursor: pointer;
                    "
                    @click="$refs['followAddDia'].open(row.orderNo)"
                    >添加人工跟进</span
                  >
                </div>
              </div>
              <!-- 发货单号/入库实际快递单号 -->
              <div
                v-else-if="
                  item.prop === 'intoExpressNo' &&
                  sendTabChoose == '02' &&
                  tabIndex == '4'
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <span
                    >{{ row["expressNo"] == "--" ? "无" : row["expressNo"] }}/{{
                      row["intoExpressNo"] == "--" ? "无" : row["intoExpressNo"]
                    }}
                  </span>
                  <span
                    v-if="
                      row['expressNo'] == row['intoExpressNo'] &&
                      row['expressNo'] !== '--' &&
                      row['intoExpressNo'] !== '--'
                    "
                    style="color: #0981fe"
                    >一致</span
                  >
                  <span v-else style="color: red">不一致</span>
                </div>
              </div>
              <!-- 实收快递是否一致 回收商  线下发货-->
              <div v-else-if="item.prop === 'intoExpressNo' && tabIndex == '6'">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <!-- <span>{{ row["expressNo"] == '--' ? '无' : row["expressNo"] }}/{{ row["intoExpressNo"] == '--' ? '无' : row["intoExpressNo"] }} </span> -->
                  <span>
                    {{
                      row["intoExpressNo"] == "--" ? "无" : row["intoExpressNo"]
                    }}
                  </span>
                  <span
                    v-if="
                      row['intoExpressNo'] == row['expressNo'] &&
                      row['intoExpressNo'] !== '--' &&
                      row['expressNo'] !== '--'
                    "
                    style="color: #0981fe"
                    >一致</span
                  >
                  <span
                    v-else-if="row['intoExpressNo'] != '--'"
                    style="color: red"
                    >不一致</span
                  >
                  <!-- <span v-else style="color:red">不一致</span> -->
                </div>
              </div>

              <!-- 线下收货时间 -->
              <div
                v-else-if="
                  item.prop === 'receiveTime' &&
                  sendTabChoose == '01' &&
                  tabIndex == '04'
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <span>{{ row.receiveTime }}</span>
                </div>
              </div>

              <div v-else-if="item.prop === 'noteNum'">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <div>
                    <span
                      style="
                        color: #0981ff;
                        cursor: pointer;
                        text-decoration: underline;
                      "
                      @click="noteFn(row)"
                      >{{ row.noteNum }}</span
                    >
                  </div>
                  <span
                    style="
                      color: red;
                      text-decoration: underline;
                      cursor: pointer;
                      color: #0981ff;
                    "
                    @click="actionClick('添加备注', row)"
                    >添加备注</span
                  >
                </div>
              </div>

              <!-- 分转后发货单号 -->
              <div v-else-if="item.prop === 'outExpressNo'">
                <!-- 00线上物流 01自提 02邮寄 -->
                <span
                  v-if="row.deliveryType == '00'"
                  @click="handleBtn(row,'outExpressNo')"
                  style="
                    text-decoration: underline;
                    cursor: pointer;
                    color: #0981ff;
                  "
                  >{{ row.outExpressNo }}</span
                >
                <span v-if="row.deliveryType == '01'">自提</span>
                <span v-if="row.deliveryType == '02'">{{
                  row.outExpressNo
                }}</span>
              </div>
              <!-- 门店发货单号 -->
              <div v-else-if="item.prop === 'expressNo'">
                <span
                v-if="row.outType=='haihuishou'"
                  @click="handleBtn(row,'expressNo')"
                  style="
                    text-decoration: underline;
                    cursor: pointer;
                    color: #0981ff;
                  "
                  >{{ row.expressNo }}</span>
                  <p v-else>{{ row.expressNo }}</p
                >
              </div>
              <div v-else>
                {{ row[item.prop] || "--" }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :meta="meta"
          @sizeChange="
            (v) => {
              meta = { ...meta, pageSize: v };
              param = { ...param, pageSize: v };
            }
          "
          @pageChange="
            (v) => {
              meta = { ...meta, pageNum: v };
              param = { ...param, pageNum: v };
            }
          "
        />
      </template>
    </GlobalChunk>
  
    <!-- 线下发货 -->
    <GlobalDialog
      @onClose="
        expressNo = '';
        offlineShow = false;
      "
      @resetPopupData="
        expressNo = '';
        offlineShow = false;
      "
      :show="offlineShow"
      title="线下发货"
      top="6%"
      :width="630"
      @submitPopupData="offlineConfig"
    >
      <div class="offline_box">
        <h2>
          说明：标记线下发货需向店员确认发货快递单号，并填写在下方。标记后，待发货不再统计该订单
        </h2>
        <p>是否确认该订单已线下发货？</p>
        <el-form>
          <el-form-item label="线下快递单号" size="medium" label-width="100px">
            <el-input
              v-model="expressNo"
              placeholder="请输入线下发货的快递单号"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </GlobalDialog>
    <!-- 取消线下发货 -->
    <GlobalDialog
      @onClose="
        command = '';
        cancelOfflineShow = false;
      "
      @resetPopupData="
        command = '';
        cancelOfflineShow = false;
      "
      :show="cancelOfflineShow"
      title="取消线下发货"
      top="6%"
      :width="630"
      @submitPopupData="cancelOfflineConfig"
    >
      <div class="offline_box">
        <div class="cancel_offline_note">
          <div>
            机型：{{ currentData.phoneName }}, 串号：{{ currentData.imei }},
            背贴码：{{
              currentData.barCode != "--"
                ? currentData.barCode
                : currentData.orderUnique
            }},
            <span style="color: red"
              >线下发货单号：{{ currentData.expressNo }}</span
            >
          </div>
        </div>
        <p>是否确认取消线下发货机？取消后订单会恢复到待发货状态</p>
        <el-form>
          <el-form-item label="动态口令" size="medium" label-width="100px">
            <el-input
              v-model="command"
              placeholder="请输入谷歌动态验证码"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </GlobalDialog>
    <!-- 暂存选择 -->
    <GlobalDialog
      @onClose="storageShow = false"
      @resetPopupData="storageShow = false"
      :show="storageShow"
      title="操作提示"
      top="6%"
      :width="630"
      @submitPopupData="saveStorage"
    >
      <div>
        <div style="margin-bottom: 10px">选择暂存原因:</div>
        <el-radio-group
          v-model="radio"
          style="
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          "
        >
          <el-radio
            :label="item.id"
            v-for="item in storageReasonList"
            :key="item.id"
            >{{ item.reason }}</el-radio
          >
        </el-radio-group>
      </div>
    </GlobalDialog>
    <!-- 旧机跟踪日志 -->
    <el-dialog title="旧机跟踪日志" :visible.sync="traceLogShow" width="33%">
      <div class="logStyle">
        <div
          style="display: flex; align-items: flex-start"
          v-for="(item, index) in logTraceArr"
          :key="index"
        >
          <span style="width: 135px; margin-right: 10px">{{
            item.createTime
          }}</span>
          <span style="flex: 1">{{ item.content }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="traceLogShow = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
    <!-- 上报异常催货 -->
    <GlobalDialog
      @onClose="
        orderNo = '';
        reportErrorShow = false;
      "
      @resetPopupData="
        orderNo = '';
        reportErrorShow = false;
      "
      :show="reportErrorShow"
      title="上报异常催货"
      top="6%"
      :width="630"
      @submitPopupData="reportErrorConfig"
    >
      <div class="offline_box">
        <div class="cancel_offline_note">
          <div>
            机型：{{ currentData.phoneName }}, 串号：{{ currentData.imei }},
            背贴码：{{
              currentData.barCode != "--"
                ? currentData.barCode
                : currentData.orderUnique
            }}
          </div>
        </div>
        <p>是否确认上报异常收货旧机</p>
      </div>
    </GlobalDialog>
    <!-- 备注添加或者编辑 -->
    <GlobalDialog
      @onClose="
        remarksValue = '';
        remarksShow = false;
      "
      @resetPopupData="
        remarksValue = '';
        remarksShow = false;
      "
      :show="remarksShow"
      :title="remarksTitle"
      top="6%"
      :width="630"
      @submitPopupData="remarksConfig"
    >
      <div class="remarks_box">
        <h2>备注内容</h2>
        <el-form>
          <el-form-item size="medium">
            <el-input
              type="textarea"
              :rows="6"
              placeholder="请输入备注内容，最多500字"
              v-model="remarksValue"
              maxlength="500"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </GlobalDialog>

    <!-- 备注列表 -->
    <el-dialog
      title="备注记录"
      :visible.sync="noteShow"
      :close-on-click-modal="false"
      width="980px"
    >
      <GlobalTable
        ref="GlobalTable"
        :columns="noteList"
        :data="noteData"
        :maxHeight="700"
        :isPagination="false"
      >
        <el-table-column label="备注人" slot="adminAcc" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.adminName }} -- {{ row.adminAcc }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注内容" slot="note" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              v-if="row.note != '--' || ''"
              class="item"
              effect="dark"
              :content="row.note"
              placement="top"
            >
              <span class="noteWrap">{{ row.note }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="noteShow = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>

    <!-- 配置列 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="配置列数据"
      :visible.sync="configShow"
      width="20%"
    >
      <GlobalTable
        ref="multipleTable"
        :columns="configList"
        :data="configData"
        :maxHeight="500"
        :isPagination="false"
        @handleSelect="handleSelectionChange"
      >
        <el-table-column type="selection" slot="checkColumn" align="center">
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="configShow = false">取 消</el-button>
        <el-button type="primary" @click="handleConfig">确 定</el-button>
      </span>
    </el-dialog>
    <!--物流详情弹框-->
    <el-dialog
      class="acc_dialog"
      title="物流详情"
      :visible.sync="LogisticsdetailsShow"
      :close-on-click-modal="false"
      width="810px"
    >
      <div v-loading="logisticsLoading">
        <div class="fzs18 b25">快递单号：{{ Logisticsarray.expressNo }}</div>

        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
          "
        >
          <div class="one">
            <div class="fzs18 b03">{{ Logisticsarray.senderCompanyName }}</div>
            <div class="fzs14">{{ Logisticsarray.senderStoreName }}</div>
          </div>

          <div class="one_img">
            <img
              src="../../../assets/images/jiantouleft.png"
              style="width: 38px;height: 11px;"
              alt=""
            />
          </div>

          <div class="one">
            <div class="fzs18 b03">
              {{ Logisticsarray.receiverMerchantName }}
            </div>
          </div>
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
          "
        >
          <div class="two">
            <div class="twoinfo">寄件人信息</div>
            <div class="twophone" v-if="Logisticsarray.senderName">
              {{ Logisticsarray.senderName }}-{{ Logisticsarray.senderMobile }}
            </div>
            <div class="twophone" v-else>
              暂无数据
            </div>
            <div v-if=" Logisticsarray.senderAddress">
              {{
                Logisticsarray.senderAddress +
                Logisticsarray.senderAddressDetail
              }}
            </div>
            <div v-else style="height: 20px;"></div>
          </div>
          <div class="two">
            <div class="twoinfo">收件人信息</div>
            <div class="twophone">
              {{ Logisticsarray.receiverName }}-{{
                Logisticsarray.receiverMobile
              }}
            </div>
            <div v-if="logisticsType=='expressNo'">
              {{
                Logisticsarray.receiverAddress
              }}
            </div>
            <div v-else>
              {{
                Logisticsarray.receiverAddress +
                Logisticsarray.receiverAddressDetail
              }}
            </div>
          </div>
        </div>

        <div class="loacinfo">物流信息</div>

        <div class="three">
          <el-timeline
            v-if="
              this.Logisticsarray.logistics &&
              this.Logisticsarray.logistics.length
            "
          >
            <el-timeline-item
              v-for="(activity, index) in Logisticsarray.logistics"
              :key="index"
              :timestamp="activity.opeRemark"
            >
              {{ activity.opeTime }}
            </el-timeline-item>
          </el-timeline>
          <el-empty
            v-else
            style="margin-top: -40px"
            description="暂无物流信息"
          ></el-empty>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="LogisticsdetailsShow = false">关闭</el-button>
      </span>
    </el-dialog>
    <FollowAddDia ref="followAddDia" @confirm="getData" />
    <FollowRecordDia ref="followRecordDia" />
    <CancelDeliveryDia ref="cancelDeliveryDia" @confirm="getData" />
    <stopGoods  :stopShopShow="stopShopShow" :stopShopdata="currentData" @submitForm="submitForm" @stopResetForm="stopResetForm"> </stopGoods>
    <stopGoodsDetail  :stopShopdetailShow="stopShopdetailShow" :detaildata="currentData" @stopdetailclose="stopdetailclose"></stopGoodsDetail>
  </div>
</template>

<script>
import _api from "@/utils/request";
// import GlobalForm from "@/components/global/components/GlobalForm.vue";
import GlobalChunk from "@/components/global/components/GlobalChunk.vue";
// import TableSelect from "@/components/common/TabSelect.vue";
import TabStatusSelect from "@/components/common/TabStatusSelect.vue";
import GlobalDialog from "@/components/global/components/GlobalDialog.vue";
import stopGoods from "../components/stopGoods.vue";
import stopGoodsDetail from "../components/stopGoodsDetail.vue"
import Pagination from "@/components/common/Pagination.vue";
import { MessageBox } from "element-ui";
import {
  getIndex,
  getMeta,
  getRecycleState,
  getStoreRole,
  guideExcelAction,
  pageSize,
  setValue,
} from "@/utils/common";
import moment from "moment";
import FollowAddDia from "./dialog/FollowAddDia.vue";
import FollowRecordDia from "./dialog/FollowRecordDia.vue";
import CancelDeliveryDia from "./dialog/CancelDeliveryDia.vue";

export default {
  components: {
    Pagination,
    GlobalDialog,
    GlobalChunk,
    TabStatusSelect,
    FollowAddDia,
    FollowRecordDia,
    CancelDeliveryDia,
    stopGoods,
    stopGoodsDetail
  },
  data() {
    return {
      stopShopdetailShow:false,
      stopShopShow:false,
      entryTime: null,
      // 物流详情数据
      LogisticsdetailsShow: false,
      Logisticsarray: {},
      logisticsLoading: false,
      logisticsType: '',
      // ==end
      priceLow: null,
      priceUp: null,
      stateList: [],
      matrixStateList: [],
      smattateArr: [
        {
          label: "待确认",
          value: "00",
        },
        {
          label: "已确认",
          value: "01",
        },
        {
          label: "待支付",
          value: "02",
        },
        {
          label: "已绑码",
          value: "03",
        },
        {
          label: "已收货",
          value: "04",
        },
        {
          label: "降价收货",
          value: "05",
        },
        {
          label: "已取消",
          value: "10",
        },
        {
          label: "已作废",
          value: "20",
        },
        {
          label: "已退回",
          value: "30",
        },
      ],
      stateArr: [
        // {
        //   label: "全部",
        //   value: "",
        // },
        {
          label: "待确认",
          value: "00",
        },
        {
          label: "已确认",
          value: "01",
        },
        {
          label: "待支付",
          value: "02",
        },
        {
          label: "已绑码",
          value: "03",
        },
        {
          label: "已收货",
          value: "04",
        },
        {
          label: "降价收货",
          value: "05",
        },
        {
          label: "已取消",
          value: "10",
        },
        {
          label: "已作废",
          value: "20",
        },
        {
          label: "已退回",
          value: "30",
        },
      ],
      filterPrice: false,
      isInStockException: "",
      Abnormalentry: false,
      queryPrice: null,
      storageReasonList: [
        {
          id: "01",
          reason: "账号/密码未退，需要跟门店沟通",
        },
        {
          id: "02",
          reason: "追差金额太高，需进一步核实",
        },
        {
          id: "03",
          reason: "追查异常，需退回繁星仓库验机后处理",
        },
        {
          id: "04",
          reason: "其他原因",
        },
      ],
      radio: "",
      radioReason: "",
      configShow: false,
      configList: [
        { label: "列名", prop: "label" },
        { slotName: "checkColumn" }, //显示
      ],
      configData: [],

      configColumnsArr: [],
      noteShow: false,
      noteList: [
        { label: "备注时间", prop: "createTime" },
        { slotName: "adminAcc" },
        { slotName: "note" }, //备注内容
      ],
      noteData: [],
      statusData: [],
      refresh: true,
      logTraceArr: [],
      sendTabChoose: "02", //收货方【01：发往回收商，02：发往平台】
      sendOutGoodsDetail: {},
      excelLoading: false,
      allExcelLoading: false,
      // 导表
      orderCodeTitle: "",
      // 备注title
      remarksTitle: "",
      // 当前数据
      currentData: {},
      // 搜索表单初始化数据
      initData: {
        ackTime: [
          moment().subtract(30, "day").startOf("day").format("YYYY-MM-DD"),
          moment().endOf("day").format("YYYY-MM-DD"),
        ],
        shouTime: null,
        into: "",
        state: "",
        companyId: this.$route.query.companyId
          ? Number(this.$route.query.companyId)
          : "",
        merchantId: this.$route.query.merchantId
          ? Number(this.$route.query.merchantId)
          : "",
        orderNo: this.$route.query.order ? this.$route.query.order : "",
      },
      // 分页
      meta: {
        total: 0,
        pageSize,
        pageNum: 1,
      },
      overdueDelivery: false,
      timeoutType: "",
      // 搜索参数
      param: {
        ackStartTime: moment()
          .subtract(30, "day")
          .startOf("day")
          .format("YYYY-MM-DD"),
        ackEndTime: moment().endOf("day").format("YYYY-MM-DD"),
        companyId: this.$route.query.companyId
          ? Number(this.$route.query.companyId)
          : "",
        merchantId: this.$route.query.merchantId
          ? Number(this.$route.query.merchantId)
          : "",
        orderNo: this.$route.query.order ? this.$route.query.order : "",
        expressState: this.$route.query.type
          ? this.$route.query.type === "0"
            ? "00"
            : this.$route.query.type === "1"
            ? "01"
            : this.$route.query.type === "2"
            ? "02"
            : "03"
          : "00",
        pageNum: 1,
        pageSize,
        ackTime: [
          moment().subtract(30, "day").startOf("day").format("YYYY-MM-DD"),
          moment().endOf("day").format("YYYY-MM-DD"),
        ],
        shouTime: null,
        receiveEndTime: "",
        receiveStartTime: "",
        into: "",
        matrixOrderNo: "",
        // state: "",
        receiptType: "02",
        isChannel: "",
        channelId: "",
        stateList: [],
        matrixStateList: [],
        priceLow: null,
        priceUp: null,
        ackTimeRankType: "00",
      },
      // 修改快递单号弹窗
      editOrderCodeShow: false,
      // 快递单号
      editOrderCode: "",
      // 备注控制
      remarksShow: false,
      // 备注
      remarksValue: "",
      // 线下快递单号
      expressNo: "",
      // 谷歌验证码
      command: "",
      // 线下发货
      offlineShow: false,
      // 取消线下发货
      cancelOfflineShow: false,
      // 标记暂存
      storageShow: false,
      // 上报异常催货
      reportErrorShow: false,
      // 旧机跟踪日志
      traceLogShow: false,
      loading: true,
      // 搜索参数
      formItemList: [
        {
          labelName: "所属商家",
          //此key相当于父
          key: "companyId",
          //需要被清除的key，相当于子
          childrenKey: "storeId",
          type: "selectFilterable",
          placeholder: "请选择或输入商家查询",
          lableWidth: "78px",
          clear: true,
          option: [],
          clearFun: () => false,
        },
        {
          labelName: "下单门店",
          fatherKey: "companyId",
          key: "storeId",
          type: "selectFilterable",
          placeholder: "请先选择门店商家",
          lableWidth: "78px",
          clear: true,
          // 代表可清空
          isClear: true,
          option: [],
          disabled: true,
          clearFun: () => false,
        },
        {
          labelName: "回收商",
          key: "merchantId",
          clear: true,
          type: "selectFilterable",
          placeholder: "请选择或输入商家查询",
          option: [],
          clearFun: () => false,
        },
        {
          labelName: "矩阵成交商家",
          key: "fxMatrixAckMerchantId",
          lableWidth: "120px",
          clear: true,
          type: "selectFilterable",
          placeholder: "请选择或输入商家查询",
          option: [],
          clearFun: () => false,
        },
        {
          labelName: "订单号",
          key: "orderNo",
          type: "input",
          placeholder: "请输入回收单号查询",
        },
        {
          labelName: "旧机串号",
          key: "imei",
          type: "input",
          placeholder: "请输入旧机串号查询",
        },
        {
          labelName: "背贴码",
          key: "barCode",
          type: "input",
          placeholder: "请输入背贴码查询",
        },
        {
          labelName: "矩阵订单号",
          key: "matrixOrderNo",
          type: "input",
          placeholder: "请输入矩阵订单号查询",
        },
        { slotName: "matrixStateList" },
        { slotName: "stateList" },
        {
          labelName: "分转仓库",
          key: "warehouseIdDptm",
          clear: true,
          type: "selectFilterable",
          placeholder: "请选择",
          lableWidth: "100px",
          option: [],
          clearFun: () => false,
        },
        {
          labelName: "外部单号",
          key: "orderNoOneline",
          type: "input",
          placeholder: "请输入外部单号查询",
          lableWidth: "100px",
        },
        {
          labelName: "发货快递单号",
          key: "expressNo",
          type: "input",
          placeholder: "请输入完整快递单号查询",
          lableWidth: "100px",
        },
        {
          labelName: "是否外部订单",
          key: "isOutOrder",
          type: "select",
          lableWidth: "120px",
          option: [
            {
              label: "是",
              value: "00",
            },
            {
              label: "否",
              value: "01",
            },
          ],
        },
        {
          labelName: "是否入库",
          key: "isInto",
          type: "select",
          option: [
            {
              label: "是",
              value: "00",
            },
            {
              label: "否",
              value: "01",
            },
          ],
        },
        {
          labelName: "入库仓库",
          key: "warehouseId",
          clear: true,
          type: "selectFilterable",
          placeholder: "请选择",
          lableWidth: "100px",
          option: [],
          clearFun: () => false,
        },
        {
          labelName: "是否上报异常",
          key: "isUpErrorTrack",
          type: "select",
          lableWidth: "120px",
          option: [
            {
              label: "是",
              value: "00",
            },
            {
              label: "否",
              value: "01",
            },
          ],
        },
        {
          labelName: "成交时间",
          key: "ackTime",
          type: "daterange",
          // valueFormat: "timestamp",
        },
        { slotName: "entryTime" },
        // {
        //   labelName: "收件时间",
        //   key: "shouTime",
        //   type: "daterange",
        //   valueFormat: "timestamp",
        // },
        {
          labelName: "是否为渠道订单",
          key: "isChannel",
          type: "select",
          lableWidth: "120px",
          option: [
            {
              label: "是",
              value: "00",
            },
            {
              label: "否",
              value: "01",
            },
          ],
          childrenKey: "channelId",
          placeholder: "请选择是否为渠道订单",
          clear: true,
          clearFun: () => false,
        },
        {
          labelName: "选择渠道",
          fatherKey: "isChannel",
          key: "channelId",
          type: "selectFilterable",
          placeholder: "请先选择渠道",
          lableWidth: "78px",
          clear: true,
          // 代表可清空
          isClear: true,
          option: [],
          disabled: true,
          clearFun: () => false,
        },
        { slotName: "operateValue" },
      ],
      // 默认存在的列  待发货 待取件
      // defaultTaleColumns: [
      //   {
      //     prop: "index",
      //     label: "序号",
      //     width: "60px",
      //   },
      //   {
      //     prop: "createCompanyName",
      //     label: "门店商家",
      //     width: "120px",
      //   },
      //   {
      //     prop: "orderNo",
      //     label: "订单号",
      //     width: "130px",
      //   },
      //   {
      //     prop: "createStoreName",
      //     label: "门店",
      //   },
      //   {
      //     prop: "ackStaffName",
      //     label: "成交店员",
      //     width: "150px",
      //   },
      //   {
      //     prop: "phoneName",
      //     label: "机型",
      //   },
      //   {
      //     prop: "imei",
      //     label: "串号",
      //   },
      //   {
      //     prop: "manualQuotePrice",
      //     label: "旧机价值",
      //   },
      //   {
      //     prop: "barCode",
      //     label: "背贴码",
      //   },
      //   {
      //     prop: "matrixOrderNo",
      //     label: "矩阵/外部订单号",
      //     width: "130px",
      //   },
      //   {
      //     prop: "manualMerchantName",
      //     label: "成交回收商",
      //     width: "100px",
      //   },
      //   {
      //     prop: "fxMatrixAckMerchantName",
      //     label: "矩阵回收商",
      //     width: "100px",
      //   },
      //   {
      //     prop: "isEnablePay",
      //     label: "是否线上支付",
      //     width: "120px",
      //   },
      //   {
      //     prop: "state",
      //     label: "订单状态",//

      //   },
      //   {
      //     prop: "ackTime",
      //     label: "成交时间",
      //     width: "140px",
      //   },
      // ],
      defaultTaleColumns: [
        {
          prop: "index",
          label: "序号",
          // width: "60px",
        },
        {
          prop: "createCompanyName",
          label: "门店商家",
          // width: "80px",
        },
        {
          prop: "orderNo",
          label: "订单号",
          width: "130px",
        },
        {
          prop: "phoneName",
          label: "机型",
          width: "100px",
        },
        {
          prop: "imei",
          label: "串号",
        },
        {
          prop: "finalPrice",
          label: "用户成交价",
        },
        {
          prop: "manualQuotePrice",
          label: "旧机价值",
        },
        {
          prop: "createStoreName",
          label: "门店",
        },
        {
          prop: "ackStaffName",
          label: "成交店员",
          width: "150px",
        },
        {
          prop: "manualMerchantName",
          label: "成交回收商",
          width: "100px",
        },
        {
          prop: "fxMatrixAckMerchantName",
          label: "矩阵回收商",
          width: "100px",
        },
        {
          prop: "warehouseName",
          label: "分转收货仓库",
          width: "100px",
        },
        {
          prop: "isReportedException",
          label: "是否上报异常机",
          width: "120px",
        },
        {
          prop: "matrixOrderNo",
          label: "矩阵/外部订单号",
          width: "130px",
        },
        {
          prop: "matrixState",
          label: "矩阵订单状态",
          width: "130px",
        },
        {
          prop: "barCode",
          label: "背贴码",
        },
        {
          prop: "state",
          label: "订单状态", //
        },
        {
          prop: "isEnablePay",
          label: "是否线上支付",
          width: "120px",
        },
        {
          prop: "ackTime",
          label: "成交时间",
          // width: "140px",
        },
        {
          prop: "errorTrackFollowCount",
          label: "催货跟进记录",
        },
        {
          prop: "noteNum",
          label: "备注",
          width: "120px",
        },
      ],
      // 渲染的列
      tableColumns: [],
      // 不同按钮对应类型
      actionInner: [
        "旧机跟踪日志",
        "线下发货",
        "添加备注",
        "取消线下发货",
        "标记暂存",
        "暂停收货",
      ],
      buttonTypeMap: {
        旧机跟踪日志: "success",
        线下发货: "primary",
        添加备注: "warning",
        取消线下发货: "danger",
        暂停收货: "danger",
        标记暂存: "warning",

      },
      defaultScreenContent: [
        "筛选超时未发货订单",
        "筛选超时未取件订单",
        "筛选超时未签收订单",
        "筛选超时未入库订单",
        "筛选在库超时订单",
        "筛选超时未收货订单",
        "筛选超时未收货订单",
      ],
      screenContent: [],
      // 渲染tab
      tabData: [],
      // tab栏数据，内置不同tab包含的列以及操作
      defaultTabData: [
        {
          paramResult: "00",
          text: "门店待发货",
          num: 0,
          name: "超时未发货",
          unNum: 0,
          // isShowNum:false,
          isHover: false,
          notion: `<div style="height:20px;line-height:20px;">1.门店待发货</div>
                    <div>统计门店成交后未发货的订单</div>
                    <div style="height:20px;line-height:20px;">2.超时未发货</div>
                    <div style="color:red;">成交后超2天未发货，标记为超时未发货</div>`,
          actionInner: ["旧机跟踪日志", "线下发货"],
          coloumns: [
            {
              prop: "dayUnSendGood",
              label: "发货异常标记",
              width: "120px",
            },
            // {
            //   prop: "isReportedException",
            //   label: "是否上报异常机",
            //   width: "120px",
            // },
            // {
            //   prop: "noteNum",
            //   label: "备注",
            //   width: "120px",
            // },
            {
              prop: "action",
              label: "操作",
              width: "220px",
            },
          ],
        },
        {
          paramResult: "01",
          text: "已下单待取件",
          num: 0,
          name: "超时未取件",
          unNum: 0,
          // isShowNum:false,
          isHover: false,
          notion: `<div style="height:20px;line-height:20px;">1.已下单待取件</div>
                    <div>旧机已下快递，等待上门取件</div>
                    <div style="height:20px;line-height:20px;">2.超时未取件</div>
                    <div style="color:red;">快递下单超1天未取件，标记为超时未取件</div>`,
          actionInner: ["旧机跟踪日志"],
          coloumns: [
            {
              prop: "expressNo",
              label: "发货单号",
            },
            {
              prop: "sendTimeRegion",
              label: "预约取件时间",
            },
            {
              prop: "dayUnGet",
              label: "取件异常标记",
            },
            // {
            //   prop: "isReportedException",
            //   label: "是否上报异常机",
            //   width: "120px",
            // },
            // {
            //   prop: "noteNum",
            //   label: "备注",
            //   width: "120px",
            // },
            {
              prop: "action",
              label: "操作",
              width: "220px",
            },
          ],
        },
        {
          paramResult: "02",
          text: "快件运输中",
          num: 0,
          name: "超时未签收",
          unNum: 0,
          // isShowNum:false,
          isHover: false,
          notion: `<div style="height:20px;line-height:20px;">1.快件运输中</div>
                    <div>旧机已发货，快递在运输中</div>
                    <div style="height:20px;line-height:20px;">2.超时未签收</div>
                    <div style="color:red;">快递下单超3天未签收，标记为超时未签收</div>`,
          actionInner: ["旧机跟踪日志"],
          coloumns: [
            {
              prop: "expressNo",
              label: "发货单号",
            },
            {
              prop: "expressCreateTime",
              label: "发货时间",
            },
            {
              prop: "dayUnSign",
              label: "签收异常标记",
            },
            // {
            //   prop: "isReportedException",
            //   label: "是否上报异常机",
            //   width: "120px",
            // },
            // {
            //   prop: "noteNum",
            //   label: "备注",
            //   width: "120px",
            // },
            {
              prop: "action",
              label: "操作",
              width: "220px",
            },
          ],
        },
        {
          paramResult: "03",
          text: "已签收待入库",
          num: 0,
          name: "超时未入库",
          unNum: 0,
          // isShowNum:false,
          isHover: false,
          notion: `<div style="height:20px;line-height:20px;">1.已签收待入库</div>
                    <div>旧机关联的快递已签收，但旧机还未入库</div>
                    <div style="height:20px;line-height:20px;">2.超时未入库</div>
                    <div style="color:red;">快递签收超2天，旧机未入库标记为超时未入库</div>`,
          actionInner: ["旧机跟踪日志"],
          coloumns: [
            {
              prop: "expressNo",
              label: "发货单号",
            },
            {
              prop: "expressSignForTime",
              label: "签收时间",
            },
            {
              prop: "dayUnInto",
              label: "入库异常标记",
            },
            // {
            //   prop: "isReportedException",
            //   label: "是否上报异常机",
            //   width: "120px",
            // },
            // {
            //   prop: "noteNum",
            //   label: "备注",
            //   width: "120px",
            // },
            {
              prop: "action",
              label: "操作",
              width: "220px",
            },
          ],
        },
        {
          paramResult: "04",
          text: "已入库待寄出",
          num: 0,
          name: "在库超时",
          unNum: 0,
          // isShowNum:false,
          isHover: false,
          notion: `<div style="height:20px;line-height:20px;">1.已入库待出库</div>
                    <div>旧机订单号/矩阵订单号已入库，等待出库</div>
                    <div style="height:20px;line-height:20px;">2.在库超时</div>
                    <div style="color:red;">入库后超3天未出库，标记为在库超时</div>`,
          actionInner: ["旧机跟踪日志"],
          coloumns: [
            {
              prop: "intoTime",
              label: "首次入库时间",
            },
            {
              prop: "intoNo",
              label: "入库单据",
            },
            {
              prop: "intoExpressNo",
              label: "发货单号/入库实际快递单号",
            },
            {
              prop: "dayInto",
              label: "在库超时标记",
            },
            // {
            //   prop: "isReportedException",
            //   label: "是否上报异常机",
            //   width: "120px",
            // },
            // {
            //   prop: "noteNum",
            //   label: "备注",
            //   width: "120px",
            // },
            {
              prop: "action",
              label: "操作",
              width: "220px",
            },
          ],
        },
        {
          paramResult: "05",
          text: "已出库待收货",
          num: 0,
          name: "收货异常",
          unNum: 0,
          // isShowNum:false,
          isHover: false,
          notion: `<div style="height:20px;line-height:20px;">1.已出库待收货</div>
                    <div>旧机已出库，等待回收商收货的订单</div>
                    <div style="height:20px;line-height:20px;">2.超时未收货</div>
                    <div style="color:red;">出库后3天未收货，标记为超时未收货</div>`,
          actionInner: ["旧机跟踪日志"],
          coloumns: [
            {
              prop: "expressNo",
              label: "门店发货单号",
            },
            {
              prop: "outCreateTime",
              label: "出库时间",
            },
            {
              prop: "outNo",
              label: "出库单据",
            },
            {
              prop: "outExpressNo",
              label: "分转后发货单号",
            },
            {
              prop: "dayOut",
              label: "回收商收货异常",
            },
            // {
            //   prop: "isReportedException",
            //   label: "是否上报异常机",
            //   width: "120px",
            // },
            // {
            //   prop: "noteNum",
            //   label: "备注",
            //   width: "120px",
            // },
            {
              prop: "action",
              label: "操作",
              width: "130px",
            },
          ],
        },
        {
          paramResult: "06",
          text: "线下发货",
          num: 0,
          name: "收货异常",
          unNum: 0,
          // isShowNum:false,
          isHover: false,
          notion: `<div style="height:20px;line-height:20px;">1.线下发货</div>
                    <div>统计使用线下发货的订单</div>
                    <div style="height:20px;line-height:20px;">2.超时未收货</div>
                    <div style="color:red;">线下发货超4天未收货，标记为超时未收货</div>`,
          actionInner: ["旧机跟踪日志"],
          coloumns: [
            {
              prop: "expressNo",
              label: "门店发货单号",
            },
            {
              prop: "expressCreateTime",
              label: "线下发货时间",
            },
            {
              prop: "receiveTime",
              label: "快递收件时间",
            },
            {
              prop: "intoTime",
              label: "旧机入库时间",
            },
            {
              prop: "normalEntry",
              label: "入库异常标记",
            },
            {
              prop: "intoExpressNo",
              label: "实收快递是否一致", // "入库快递单号与实际快递是否一致",
              width: "120px",
            },
            {
              prop: "dayOffUnTake",
              label: "回收商收货异常",
            },
            // {
            //   prop: "isReportedException",
            //   label: "是否上报异常机",
            //   width: "120px",
            // },
            // {
            //   prop: "noteNum",
            //   label: "备注",
            //   width: "120px",
            // },
            {
              prop: "action",
              label: "操作",
              width: "240px",
            },
          ],
        },
      ],
      sendItems: [
        {
          id: "02",
          text: "发往平台",
          // sendPlatform: 100000,
        },
        {
          id: "01",
          text: "发往回收商",
          // sendPlatform: 9990,
        },
      ],
      // 当前索引
      tabIndex: 0,
      // 列表数据
      tableData: [],
    };
  },
  watch: {
    filterPrice(val) {
      console.log("200以上价格", val);
      if (val == true) {
        this.queryPrice = "200";
      } else {
        this.queryPrice = null;
      }
      this.param = {
        ...this.param,
        queryPrice: this.queryPrice,
      };
    },
    Abnormalentry(val) {
      this.param = {
        ...this.param,
        isInStockException: val ? "00" : "01",
      };
    },
    param() {
      this.getData();
    },
    sendTabChoose(val) {
      // this.tabData = [...this.defaultTabData];
      this.screenContent = [...this.defaultScreenContent];
      // 发往回收商
      if (val == "01") {
        // this.tabData.splice(4, 2);
        this.screenContent = [
          "筛选超时未发货订单",
          "筛选超时未取件订单",
          "筛选超时未签收订单",
          "筛选未收货订单",
          "筛选超时未收货订单",
        ];
        // this.screenContent.splice(4, 2);
      }
      // 切换发往xx时，清空台数显示
      // this.tabData.forEach((item) => {
      //   item.isShowNum = false
      // })
      // this.tabData[0].isShowNum = true
    },
    timeoutType(v) {
      console.log("========", v, this.tabData[this.tabIndex], this.tabIndex);
      let param = this.param;
      if (v) {
        let timeoutType = "";

        if (this.sendTabChoose == "02") {
          if (this.tabData[this.tabIndex].paramResult == "00") {
            timeoutType = "00";
          } else if (this.tabData[this.tabIndex].paramResult == "01") {
            timeoutType = "01";
          } else if (this.tabData[this.tabIndex].paramResult == "02") {
            timeoutType = "02";
          } else if (this.tabData[this.tabIndex].paramResult == "03") {
            timeoutType = "03";
          } else if (this.tabData[this.tabIndex].paramResult == "04") {
            timeoutType = "04";
          } else if (this.tabData[this.tabIndex].paramResult == "05") {
            timeoutType = "05";
          } else {
            timeoutType = "06";
          }
        } else if (this.sendTabChoose == "01") {
          if (this.tabData[this.tabIndex].paramResult == "00") {
            timeoutType = "00";
          } else if (this.tabData[this.tabIndex].paramResult == "01") {
            timeoutType = "01";
          } else if (this.tabData[this.tabIndex].paramResult == "02") {
            timeoutType = "02";
          } else if (this.tabData[this.tabIndex].paramResult == "03") {
            timeoutType = "03";
          } else {
            timeoutType = "06";
          }
        }
        this.param = {
          ...this.param,
          timeoutType,
        };
      } else {
        this.param = {
          ...this.param,
          timeoutType: "",
        };
      }
    },
  },
  created() {
    // 获取下拉
    this.getSelectData();
    this.getwarehouseDown();
    this.tabData = [...this.defaultTabData];
    this.screenContent = [...this.defaultScreenContent];
    // this.tabData[0].isShowNum = true
    // 设置table列
    this.setTableColoumns(0);
  },
  mounted() {},
  methods: {
    stopdetailclose(){
     this.stopShopdetailShow=false
    },
    submitForm(data) {
      console.log(data);
        this.stopShopShow=false
        this.getData();
      },
    stopResetForm() {
      this.stopShopShow=false
    },
    //获取仓库
    getwarehouseDown() {
      _api.getwarehouseSelectList().then((res) => {
        const warehouseIndex = this.formItemList.findIndex(
          (v) => v.key === "warehouseId"
        );
        const warehouseIdDptmIndex = this.formItemList.findIndex(
          (v) => v.key === "warehouseIdDptm"
        );
        this.formItemList[warehouseIndex].option = res.data.map((v) => {
          return {
            label: v.warehouseName,
            value: v.id,
          };
        });
        this.formItemList[warehouseIdDptmIndex].option = res.data.map((v) => {
          return {
            label: v.warehouseName,
            value: v.id,
          };
        });
      });
    },
    clearPrice(type) {
      if (type == "low") {
        this.priceLow = null;
      } else {
        this.priceUp = null;
      }
    },
    priceChang(val, type) {
      if (type == "low") {
        this.priceLow = +val;
      } else {
        this.priceUp = +val;
      }
    },
    // 物流详情弹窗
    handleBtn(row,type) {
      this.logisticsLoading = true;
      this.LogisticsdetailsShow = true;
      this.logisticsType=type;
      if(type=='expressNo'){
        _api.gethaihiushouLogisticsInfo({ expressNo: row.expressNo,orderNo:row.orderNo }).then((res) => {
        console.log("分转", res);
        this.Logisticsarray = res.data;
        this.logisticsLoading = false;
      });
      }else{
        {
        _api.trackLogisticsInfo({ expressNo: row.outExpressNo }).then((res) => {
        console.log("分转", res);
        this.Logisticsarray = res.data;
        this.logisticsLoading = false;
      });
      }
      }
     
    },
    // 配置列按钮
    handleConfigColumns() {
      this.configShow = true;
      if (!this.configColumnsArr.length) {
        this.configColumnsArr = this.configData;
      }
      console.log(this.configColumnsArr, "#$%2", this.configData);
      this.$nextTick(() => {
        this.configColumnsArr.forEach((item) => {
          this.$refs.multipleTable.$refs.tableGroup.toggleRowSelection(
            item,
            true
          );
        });
      });
    },
    // // 重置配置列
    // cancelConfig(){
    //   this.$refs.multipleTable.clearSelection();
    //   this.configColumnsArr = []
    // },
    // 确定
    handleConfig() {
      console.log("%%", this.configColumnsArr);
      if (!this.configColumnsArr.length) {
        return this.$message.error("列数据不能为空！");
      }
      this.tableColumns = [
        { prop: "index", label: "序号" },
        ...this.configColumnsArr,
      ];
      this.configShow = false;
    },
    handleSelectionChange(row) {
      this.configColumnsArr = row;
    },
    // 备注记录
    noteFn(row) {
      this.noteShow = true;
      _api.getNoteList({ orderNo: row.orderNo }).then((res) => {
        this.noteData = res.data;
        this.page.total = res.data.length;
      });
    },
    acitonsendTabChoose(index) {
      this.sendTabChoose = index;
      this.param.receiptType = index;
      this.tabAction(0);
    },
    handleChange(v1, v2) {
      if (v1.key === "companyId") {
        const index = this.formItemList.findIndex(
          (v) => v.key === v1.childrenKey
        );
        if (v2) {
          this.formItemList[index].disabled = false;
          this.getStoreList(v2);
        } else {
          this.formItemList[index].disabled = true;
        }
      }
      console.log("!!", v1, v2);
      if (v1.key === "isChannel") {
        const index = this.formItemList.findIndex(
          (v) => v.key === v1.childrenKey
        );

        if (v2 == "00") {
          this.formItemList[index].disabled = false;
          this.getMatchChannelSelect(v2);
        } else {
          this.formItemList[index].disabled = true;
        }
      }
    },
    handleChangeState(val) {
      this.stateList = val;
    },
    resetForm(pageNum = 1) {
      this.tableData = [];
      this.loading = true;
      this.meta = {
        ...this.meta,
        pageNum,
      };
      this.param = {
        ...this.param,
        ...this.meta,
      };
    },
    // 请求下单门店下拉
    getStoreList(companyId) {
      _api.getStoreSelectList({ companyId }).then((r) => {
        const data = r.data || [];
        const index = this.formItemList.findIndex((v) => v.key === "storeId");
        this.formItemList[index].option = data.map((v) => {
          return {
            label: v.value,
            value: v.key,
          };
        });
      });
    },
    // 请求渠道下拉
    getMatchChannelSelect(companyId) {
      _api.getMatchChannelSelect().then((r) => {
        const index = this.formItemList.findIndex((v) => v.key === "channelId");
        const data = Array.isArray(r.data) ? r.data : [];
        this.formItemList[index].option = data.map((v) => {
          return {
            value: v.id,
            label: v.name,
          };
        });
      });
    },
    // 下拉列表数据
    async getSelectData() {
      const retrieveRes = await _api.listSimpleMerchantView({
        pageNum: 1,
        pageSize: 9999,
      });
      // 回收商家下拉
      if (retrieveRes.code === 1) {
        const hsIndex = this.formItemList.findIndex(
          (v) => v.key === "merchantId"
        );
        this.formItemList[hsIndex].option = retrieveRes.data.map((v) => {
          return {
            label: v.merchantName,
            value: v.merchantId,
          };
        });
        // 矩阵成交商家
        const matruxIndex = this.formItemList.findIndex(
          (v) => v.key === "fxMatrixAckMerchantId"
        );
        this.formItemList[matruxIndex].option = retrieveRes.data.map((v) => {
          return {
            label: v.merchantName,
            value: v.merchantId,
          };
        });
      }
      // 所属商家下拉
      const storeRes = await _api.getSelectList();
      if (storeRes.code === 1) {
        this.formItemList[0].option = storeRes.data.map((v) => {
          return {
            label: v.name,
            value: v.id,
          };
        });
      }
    },
    // 列表请求
    getData() {
      this.param.stateList = this.stateList;
      this.param.matrixStateList = this.matrixStateList;
      if (this.priceLow && !this.priceUp) {
        this.param.priceLow = this.priceLow;
        this.param.priceUp = 99999;
        this.priceUp = 99999;
      } else if (!this.priceLow && this.priceUp) {
        this.param.priceUp = this.priceUp;
        this.param.priceLow = 1;
        this.priceLow = 1;
      } else if (!this.priceLow && !this.priceUp) {
        this.param.priceLow = null;
        this.param.priceUp = null;
      } else {
        this.param.priceLow = this.priceLow;
        this.param.priceUp = this.priceUp;
      }
      // 每次请求前格式化时间参数
      let param = { ...this.param };
      if (param.ackTime || this.entryTime !== null) {
        let ackStartTime = null;
        let ackEndTime = null;
        let receiveEndTime = null;
        let receiveStartTime = null;
        if (this.entryTime !== null) {
          receiveStartTime = moment(this.entryTime[0]).format("x");
          receiveEndTime = moment(this.entryTime[1]).format("x");
        }
        if (param.ackTime !== null) {
          ackStartTime = moment(param.ackTime[0] + " " + "00:00:00").format(
            "x"
          );
          ackEndTime = moment(param.ackTime[1] + " " + "23:59:59").format("x");
        }
        param = {
          ...param,
          ackStartTime,
          ackEndTime,
          receiveStartTime,
          receiveEndTime,
        };
      } else {
        param = {
          ...param,
          ackStartTime: null,
          ackEndTime: null,
          receiveStartTime: null,
          receiveEndTime: null,
        };
      }
      this.loading = true;
      _api
        .dptmList(param)
        .then((r) => {
          if (r.code === 1) {
            this.loading = false;
            this.tableData = r.data.list.records.map((v, i) => {
              v = setValue(v);
              return {
                ...v,
                index: getIndex(r.data.list.current, r.data.list.size, i),
                state: getRecycleState(v.state),
                receiptType:
                  v.receiptType === "01"
                    ? "回收商"
                    : v.receiptType === "02"
                    ? "平台"
                    : "--",
                createStoreInfo: `${
                  v.createStaffName === "--" ? "--" : v.createStaffName
                }${
                  v.createStaffMobile === "--" ? "" : "-" + v.createStaffMobile
                }`,
                ackStaffIsMajor: v.ackStaffIsMajor
                  ? "创建人"
                  : getStoreRole(v.ackStaffType),
                // senderType: v.senderIsMajor ? "创建人" : getStoreRole(v.senderType),
                manualQuotePrice:
                  v.manualQuotePrice === "--"
                    ? "--"
                    : "￥" + v.manualQuotePrice,
              };
            });
            this.meta = getMeta(
              r.data.list.total,
              r.data.list.current,
              r.data.list.size
            );
            if (this.sendTabChoose == "02") {
              this.statusData = this.defaultTabData;
              this.statusData[0].num = r.data.unSendGoodNum;
              this.statusData[0].unNum = r.data.unSendGoodTimeoutNum;
              this.statusData[1].num = r.data.unGetNum; //已下单待取件
              this.statusData[1].unNum = r.data.unGetTimeoutNum; //超时未取件
              this.statusData[2].num = r.data.unSignNum; //快件运输中
              this.statusData[2].unNum = r.data.unSignTimeoutNum; //超时未签收
              this.statusData[3].num = r.data.unIntoNum; //已签收待入库
              this.statusData[3].unNum = r.data.unIntoTimeoutNum; //超时未入库
              this.statusData[4].num = r.data.unExpressNum; //已入库待寄出
              this.statusData[4].unNum = r.data.unExpressTimeoutNum; //在库超时
              this.statusData[5].num = r.data.unTakeNum; //已出库待收货
              this.statusData[5].unNum = r.data.unTakeTimeoutNum; //收货异常
              this.statusData[6].num = r.data.offUnTakeNum; //线下发货-未签收
              this.statusData[6].unNum = r.data.offUnTakeTimeoutNum; //收货异常
            } else {
              this.testdata = {
                paramResult: "03",
                text: "已签收待收货",
                num: 0,
                name: "超时未收货",
                unNum: 0,
                // isShowNum:false,
                isHover: false,
                notion: `<div style="height:20px;line-height:20px;">1.已签收待收货</div>
                                  <div>旧机关联的快递已签收，但旧机还未入库</div>
                                  <div style="height:20px;line-height:20px;">2.超时未收货</div>
                                  <div style="color:red;">快递签收超2天，旧机未入库标记为超时未入库</div>`,
                actionInner: ["旧机跟踪日志"],
                coloumns: [
                  {
                    prop: "expressNo",
                    label: "发货单号",
                  },
                  {
                    prop: "expressSignForTime",
                    label: "签收时间",
                  },
                  {
                    prop: "dayUnTake",
                    label: "收货异常标记",
                  },
                  // {
                  //   prop: "isReportedException",
                  //   label: "是否上报异常机",
                  //   width: "120px",
                  // },
                  // {
                  //   prop: "noteNum",
                  //   label: "备注",
                  //   width: "120px",
                  // },
                  {
                    prop: "action",
                    label: "操作",
                    width: "220px",
                  },
                ],
              };
              (this.testData2 = {
                paramResult: "06",
                text: "线下发货",
                num: 0,
                name: "收货异常",
                unNum: 0,
                // isShowNum:false,
                isHover: false,
                notion: `<div style="height:20px;line-height:20px;">1.线下发货</div>
                          <div>统计使用线下发货的订单</div>
                          <div style="height:20px;line-height:20px;">2.超时未收货</div>
                          <div style="color:red;">线下发货超4天未收货，标记为超时未收货</div>`,
                actionInner: ["旧机跟踪日志"],
                coloumns: [
                  {
                    prop: "expressNo",
                    label: "门店发货单号",
                  },
                  {
                    prop: "expressCreateTime",
                    label: "线下发货时间",
                  },
                  {
                    prop: "intoExpressNo",
                    label: "繁星入库实收快递单号",
                  },
                  {
                    prop: "receiveTime",
                    label: "回收商收件时间",
                  },
                  {
                    prop: "intoTime",
                    label: "旧机入库时间",
                  },
                  {
                    prop: "normalEntry",
                    label: "入库异常标记",
                  },
                  {
                    prop: "dayOffUnTake",
                    label: "回收商收货异常",
                  },
                  // {
                  //   prop: "isReportedException",
                  //   label: "是否上报异常机",
                  //   width: "120px",
                  // },
                  // {
                  //   prop: "noteNum",
                  //   label: "备注",
                  //   width: "120px",
                  // },
                  {
                    prop: "action",
                    label: "操作",
                    width: "240px",
                  },
                ],
              }),
                (this.statusData = this.defaultTabData.slice(0, 3));
              this.statusData.push(this.testdata, this.testData2);
              this.statusData[0].num = r.data.unSendGoodNum;
              this.statusData[0].unNum = r.data.unSendGoodTimeoutNum;
              this.statusData[1].num = r.data.unGetNum; //已下单待取件
              this.statusData[1].unNum = r.data.unGetTimeoutNum; //超时未取件
              this.statusData[2].num = r.data.unSignNum; //快件运输中
              this.statusData[2].unNum = r.data.unSignTimeoutNum; //超时未签收
              this.statusData[3].num = r.data.unIntoNum; //已签收待收货
              this.statusData[3].unNum = r.data.unIntoTimeoutNum; //超时未入库
              this.statusData[4].num = r.data.offUnTakeNum; //线下发货-未签收
              this.statusData[4].unNum = r.data.offUnTakeTimeoutNum; //收货异常
            }
            this.tabData = this.statusData;
          }
          if (typeof this.param.disuseLoding === "function") {
            this.param.disuseLoding();
          }
        })
        .catch(() => {
          this.loading = false;
          this.tabData = this.tabData.map((v) => {
            return {
              ...v,
              num: 0,
            };
          });
          if (typeof this.param.disuseLoding === "function") {
            this.param.disuseLoding();
          }
        });
    },
    // 获取旧机跟踪日志
    getTraceLog(param) {
      _api.getTraceLog(param).then((r) => {
        if (r.code) {
          console.log("===", r.data);
          this.logTraceArr = r.data;
        }
      });
    },
    // 获取线下发货详情
    getOfflineExpressInfo(orderNo, cb) {
      _api.offlineExpressInfo(orderNo).then((r) => {
        if (r.code) {
          const v = r.data;
          for (const key in v) {
            // 去重不存在的数据
            if (v[key] === undefined || v[key] === null || v[key] === "") {
              v[key] = "--";
            }
            // 拼接发货信息
            v["expressSendInfo"] = `${
              v["expressSendStaffName"] === "--"
                ? "--"
                : v["expressSendStaffName"]
            }${
              v["expressSendStaffMobile"] === "--"
                ? ""
                : "-" + v["expressSendStaffMobile"]
            }`;
            // 设置发货商
            if (key === "expressSendType") {
              switch (v[key]) {
                case "00":
                  v[key] = "平台";
                  break;
                case "01":
                  v[key] = "门店";
                  break;
                case "02":
                  v[key] = "回收商";
                  break;
                default:
                  v[key] = "--";
                  break;
              }
            }
          }
          this.sendOutGoodsDetail = v;
          cb();
        }
      });
    },
    // 操作点击
    actionClick(v, row) {
      // 保存当前行
      this.currentData = row;
      console.log("~~~~~~", row);
      switch (v) {
        case "线下发货":
          this.offlineShow = true;
          break;
        case "取消线下发货":
          this.cancelOfflineShow = true;
          break;
        case "上报异常催货":
          this.reportErrorShow = true;
          break;
        case "标记暂存":
          this.storageShow = true;
          break;
        case "旧机跟踪日志":
          this.traceLogShow = true;
          this.getTraceLog({
            expressNo:
              this.currentData.expressNo != "--"
                ? this.currentData.expressNo
                : "",
            orderNo:
              this.currentData.orderNo != "--" ? this.currentData.orderNo : "",
          });
          break;
        case "添加备注":
          this.remarksShow = true;
          this.remarksTitle = v;
          break;
        case "暂停收货":
          this.stopShopShow=true
          console.log('暂停收货',this.stopShopShow);
          break;
          case "暂停收货详情":
          this.stopShopdetailShow=true
          console.log('暂停收货详情',this.stopShopdetailShow);
          break;
        case "修改快递单号":
          this.getOfflineExpressInfo(this.currentData.orderNo, () => {
            this.editOrderCodeShow = true;
            this.orderCodeTitle = v;
            this.orderNum = this.sendOutGoodsDetail.expressNo;
          });
          break;
        case "添加快递单号":
          this.getOfflineExpressInfo(this.currentData.orderNo, () => {
            this.editOrderCodeShow = true;
            this.orderCodeTitle = v;
          });
          break;
      }
    },
    // 线下发货
    offlineConfig() {
      if (!this.expressNo) {
        return this.$message.error("请输入线下快递单号");
      }
      const param = {
        orderNo: this.currentData.orderNo,
        expressNo: this.expressNo,
      };
      _api.dptmBindExpress(param).then((r) => {
        if (r.code) {
          this.$message.success("操作成功");
          // 关闭弹窗
          this.offlineShow = false;
          // 关闭弹窗
          this.editOrderCodeShow = false;
          this.orderNum = "";
          // 重拉请求
          this.resetForm(this.meta.pageNum);
        }
      });
    },
    // 取消线下发货
    cancelOfflineConfig() {
      if (!this.command) {
        return this.$message.error("请输入动态口令");
      }
      const param = {
        orderNo: this.currentData.orderNo,
        command: this.command ?? "",
      };
      _api.cancelOfflineSend(param).then((r) => {
        if (r.code) {
          this.$message.success("操作成功");
          // 关闭弹窗
          this.cancelOfflineShow = false;
          this.command = "";
          // 重拉请求
          this.resetForm(this.meta.pageNum);
        }
      });
    },
    //
    saveStorage() {
      console.log("暂存");
      this.storageShow = false;
    },
    // 上报异常催货
    reportErrorConfig() {
      const param = {
        orderNo: this.currentData.orderNo,
      };
      _api.upErrorTrack(param).then((r) => {
        if (r.code) {
          this.$message.success("上报异常操作成功");
          // 关闭弹窗
          this.reportErrorShow = false;
          // 重拉请求
          this.resetForm(this.meta.pageNum);
        }
      });
    },
    // 添加或修改备注
    remarksConfig() {
      const param = {
        note: this.remarksValue,
        orderNo: this.currentData.orderNo ?? "",
      };
      _api.dptmNote(param).then((r) => {
        if (r.code) {
          this.$message.success("操作成功");
          this.remarksShow = false;
          this.remarksValue = "";
          // 重新请求
          this.resetForm(this.meta.pageNum);
        }
      });
    },
    // 更新列
    setTableColoumns(i) {
      this.refresh = false;
      // 必存在的列注意指针引用问题)
      let tableColumns = [...this.defaultTaleColumns];
      // 发往回收商 去掉 分转收货仓库
      if (this.sendTabChoose === "01") {
        tableColumns = tableColumns.filter(
          (item) => item.prop !== "warehouseName"
        );
      }

      // 获取添加的位置
      this.tabData[i].coloumns.forEach((v, ix) => {
        tableColumns.splice(10 + ix, 0, v);
      });
      // DOM更新后操作列
      this.$nextTick(() => {
        this.tableColumns = tableColumns;
        // 配置列时，去除索引选项
        const arr = [...tableColumns];
        arr.shift();
        this.configData = arr;
        if (i) {
          const index = this.tableColumns.findIndex(
            (v) => v.label === "矩阵订单号"
          );
        }
      });
      this.refresh = true;
      this.resetForm();
    },
    // 查询操作
    handleConfirm(v, cd) {
      console.log(v);
      v = {
        ...v,
      };
      this.param = {
        ...this.param,
        ...v,
        fxMatrixAckMerchantId: v.fxMatrixAckMerchantId
          ? v.fxMatrixAckMerchantId
          : undefined,
        disuseLoding: () => {
          if (typeof cd === "function") {
            cd();
          }
        },
      };
      this.resetForm();
    },
    // 发货状态tab
    tabAction(i) {
      // if(this.tabIndex===i ) return
      console.log(i, this.tabData[i].paramResult, this.param.expressState);
      this.timeoutType = "";
      this.filterPrice = false;
      this.Abnormalentry = false;
      this.configColumnsArr = [];
      // this.tabData[this.tabIndex].isShowNum = false
      this.tabIndex = i;
      // this.tabData[this.tabIndex].isShowNum = true
      this.param = {
        ...this.param,
        expressState: this.tabData[i].paramResult,
        timeoutType: this.timeoutType[i] || "",
      };
      this.setTableColoumns(i);
    },
    timeRankChange() {
      this.param.ackTimeRankType =
        this.param.ackTimeRankType == "00" ? "01" : "00";
      this.getData();
    },
    // 导表
    exportExcel(type) {
      let param = { ...this.param };
      let ackStartTime = null;
      let ackEndTime = null;
      let receiveEndTime = null;
      let receiveStartTime = null;
      if (this.entryTime !== null) {
        receiveStartTime = moment(this.entryTime[0]).format("x");
        receiveEndTime = moment(this.entryTime[1]).format("x");
      }
      if (param.ackTime !== null) {
        ackStartTime = moment(param.ackTime[0] + " " + "00:00:00").format("x");
        ackEndTime = moment(param.ackTime[1] + " " + "23:59:59").format("x");
      }
      param = {
        ...param,
        ackEndTime,
        ackStartTime,
        receiveStartTime,
        receiveEndTime,
      };
      // let request;
      // if (type === "all") {
      //   this.allExcelLoading = true;
      //   request = _api.allDptmExcel({ ...param, expressState: null });
      // } else {
      this.excelLoading = true;
      // request = _api.dptmExcel(param);
      // }
      // request.then((r) => {
      //   // 导表
      //   guideExcelAction(r, "旧机发货跟踪", () => {
      //     if (type === "all") {
      //       this.allExcelLoading = false;
      //     } else {
      //       this.excelLoading = false;
      //     }
      //   });
      //   this.$message.success("操作成功");
      // });
      _api
        .dptmExcel(param)
        .then((res) => {
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                MessageBox.alert(data.msg, "错误提示", {
                  type: "error",
                });
              }
            } catch (err) {
              const fileName = "旧机发货跟踪.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8");

          this.excelLoading = false;
        })
        .catch((err) => {
          this.excelLoading = false;
        });
    },
    optionsFilter(v, options) {
      return options.find((item) => item.value == v)?.label;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.merchant-add {
  width: 100%;

  .tab_inner {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.one {
  flex: 1;
  text-align: center;
}

.one_img {
  width: 38px;
  height: 11px;
}

.fzs18 {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
}

.b25 {
  margin-bottom: 15px;
}

.fzs14 {
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #666666;
}

.b03 {
  margin-bottom: 3px;
}

.two {
  max-height: 124px;
  background: #f5f6fa;
  border-radius: 6px;
  width: 360px;
  padding: 10px 13px 15px 13px;
  box-sizing: border-box;
}

.twoinfo {
  font-size: 16px;
  font-family: FZLanTingHei-DB-GBK;
  font-weight: 400;
  color: #0981ff;
  margin-bottom: 6px;
}

.twophone {
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #333333;
  margin-bottom: 6px;
}

.loacinfo {
  font-size: 16px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #333333;
  margin-top: 24px;
}

.three {
  min-height: 100px;
  background: #f9fbfd;
  border: 1px solid #c1d1ff;
  border-radius: 10px;
  margin-top: 12px;
  margin-bottom: 16px;
  max-height: 200px;
  overflow: auto;
  padding: 20px 20px;
  box-sizing: border-box;
  padding-bottom: 0;
}

.three::-webkit-scrollbar {
  width: 3px;
  // display: none;
}

/*定义滚动条轨道*/
.three::-webkit-scrollbar-track {
  border-radius: 10px;
  // display: none;
}

/*定义滑块*/
.three::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(27, 140, 236, 0.5);
  display: none;
}

.noteWrap {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.offline_box {
  width: 100%;

  > h2 {
    color: #ff687b;
    font-size: 12px;
    font-weight: 900;
  }

  > p {
    font-size: 14px;
    margin: 20px 0;
    color: #333;
    text-align: center;
  }

  .cancel_offline_note {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f2f2f2;
    height: 55px;
  }
}

.logStyle {
  border: 1px solid #5a8eff;
  //width: 590px;
  min-height: 50px;
  max-height: 400px;
  overflow-y: scroll;
  padding: 10px;
  border-radius: 6px;
}

.remarks_box {
  width: 100%;

  > h2 {
    color: #666;
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.edit_order_box {
  width: 100%;
  font-size: 17px;
  color: #111;

  > h2 {
    margin-bottom: 16px;
    color: #ff687b;
    font-size: 12px;
    font-weight: 900;
  }

  > p {
    margin-bottom: 16px;
    color: #666;
    font-size: 14px;

    > i {
      font-style: normal;
      position: relative;

      > span {
        font-size: 12px;
        display: inline-block;
        margin-left: 10px;
        width: 50px;
        padding: 1px 0;
        transform: translateY(-2px);
        //#80C269 #F59A23
        background: #80c269;
        text-align: center;
        border-radius: 9px 8px 8px 0;
        color: #fff;
      }
    }
  }
}

.text_blue {
  color: #0981ff;
  border-bottom: 1px solid #0981ff;
}

.SendTo {
  display: flex;
  width: 200px;
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  cursor: pointer;
  color: #333;
  font-weight: 700;
  line-height: 38px;
  height: 38px;
  text-align: center;
  margin: 5px 10px 10px;

  .sendItem {
    position: relative;
    width: 100px;
  }

  .sendActive {
    color: #4c84ff;
    font-size: 18px;

    &::before {
      content: "";
      width: 55px;
      height: 2px;
      border-radius: 1px;
      background-color: #5a8eff;
      position: absolute;
      top: 36px;
      left: 20px;
    }
  }
}
</style>
